
import React, { useState, useEffect } from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";
import SiteWrapper from "../../SiteWrapper";

import * as userService from "../../store/services/userServices";
import { ROLE_ADMIN, ROLE_CUSTOMER, ROLE_DEALER } from "../../store/types";


function Users(props) {

    const [user, setUser] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});


    const [ loading, setLoading ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ filter, setFilter ] = useState(user.type === ROLE_DEALER ? ROLE_CUSTOMER: "");

    const [ totalPage, setTotalPage ] = useState(1);
    const [ currentPage, setCurrentPage ] = useState(1);

    useEffect(() => {
        setLoading(true);

        userService
            .getUsers(filter, (user?.type === ROLE_ADMIN ? 0: user?.id), currentPage)
            .then(res => {
                const data = JSON.parse(res.data);

                setUsers(data.data?.data);
                setTotalPage(data?.data?.last_page);
                setCurrentPage(data?.data?.current_page);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })

    }, [  ])


    const handleFilter = ( e ) => {
        setLoading(true);
        setFilter( e.target.value );

        userService
        .getUsers(e.target.value, (user?.type === ROLE_ADMIN ? 0: user?.id), currentPage)
        .then(res => {
            const data = JSON.parse(res.data);
            setUsers(data?.data?.data);
            setTotalPage(data?.data?.last_page);
            setCurrentPage(data?.data?.current_page);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
        });
    }

    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header d-flex justify-content-between">
                    <h1 className="title">Users</h1>

                    <Button 
                        className="btn-barlows d-flex justify-content-center align-items-center"
                        onClick={() => {
                            props.history.push("/users/0");
                        }}
                    >
                        Add New
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 1.5V8.5" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                            <path d="M8 5H1" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                        </svg>
                    </Button>
                </div>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Title>Users</Card.Title>

                                {
                                    user.type !== "Dealer" && 
                                    <Form.Group >
                                        <Form.Select onChange={handleFilter} value={filter}>
                                            <option value="">Filter By Role</option>
                                            <option>Admin</option>
                                            <option>Dealer</option>
                                            <option>Customer</option>
                                        </Form.Select>
                                    </Form.Group>
                                }
                            </Card.Header>
                        {users.length > 0 ?
                            <Table
                                cards={true}
                                striped={true}
                                responsive={true}
                                className="table card-table table-vcenter datatable"
                            >
                                <Table.Header>
                                    <Table.Row>
                                    <Table.ColHeader>NAME</Table.ColHeader>
                                    <Table.ColHeader>ROLE</Table.ColHeader>
                                    <Table.ColHeader>ID</Table.ColHeader>
                                    <Table.ColHeader>Dealer</Table.ColHeader>
                                    <Table.ColHeader />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {users.map((user, key) => (
                                    <Table.Row key={key}>
                                        <Table.Col>{ `${user.name} ${user?.last_name}` }</Table.Col>
                                        <Table.Col>{ user.type }</Table.Col>
                                        <Table.Col>{ user.id }</Table.Col>
                                        <Table.Col>
                                            { 
                                                user?.dealer?.id && 
                                                `${String(user?.dealer?.id || "").padStart(4, '0')} - ${user?.dealer?.name || ""} ${user?.dealer?.last_name || ""}`
                                            }
                                        </Table.Col>
                                        <Table.Col>
                                        <Button
                                            color="secondary"
                                            onClick={() => {
                                                props.history.push("/users/" + user.id);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        </Table.Col>
                                    </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table> :
                            <Card.Body className='align-items-center justify-content-center'>
                                <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                                    No Users
                                </h4>
                            </Card.Body>
                            }
                            <Card.Footer className="justify-between">
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setCurrentPage((prev) => {
                                            if(prev == 1) {
                                                return prev;
                                            } else {
                                                return prev - 1;
                                            }
                                        });
                                    }}
                                >
                                    Prev
                                </Button>
                                <div>
                                    {
                                        `${currentPage} of ${totalPage}`
                                    }
                                </div>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setCurrentPage((prev) => {
                                            if(prev + 1 > totalPage) {
                                                return prev;
                                            } else {
                                                return prev + 1;
                                            }
                                        });
                                    }}
                                >
                                    Next
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export default Users;