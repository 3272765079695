let api_url = "http://localhost:8000/api/";
let remote_url = "http://localhost:7000/api/occp16/ws/";
let socket_url = "ws://localhost:7000/status";
let url = "https://api.mm.kijohosting.com/";
let url1 = "http://mm.kijotest.co.uk";


if (process.env.NODE_ENV === "development") {
  api_url = "https://api.mm.kijohosting.com/api/";
  // api_url = "http://localhost:8000/api/";
  // url = "http://localhost:8000/";
}

if (process.env.NODE_ENV === "production") {
  api_url = "https://api.mm.kijohosting.com/api/";
  remote_url = "https://socket.evaa.app/api/occp16/ws/";
  socket_url = "wss://socket.evaa.app/status";
}

export const BACKENDURL = url;
export const FRONTENDURL = url1;
export const BASE_URL = api_url;
export const REMOTE_URL = remote_url;
export const SOCKET_URL = socket_url;
export const STORAGE_URL = "https://api.mm.kijohosting.com/storage/"